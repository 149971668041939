<template>
  <div class="text-center">
    <v-menu open-on-hover bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn large text icon color="primary" v-on="on">
          <fa-icon icon="user-circle" size="3x"></fa-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in visibleItems"
          :key="index"
          @click="item.click"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "UserSettingsMenu",
  data() {
    return {
      items: [
        {
          text: this.$t("settings.changePassword"),
          click: this.changePassword,
          visible: this.$store.state.user.isLocalUser
        },
        { text: this.$t("settings.logout"), click: this.logout, visible: true }
      ]
    };
  },
  computed: {
    visibleItems() {
      return this.items.filter(item => item.visible);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("RESET_STATE").then(name => {
        if (name) {
          this.$router.push({ name: name });
        }
      });
    },
    changePassword() {
      this.$router.push({ name: "changePassword" });
    }
  }
};
</script>
