<template>
  <v-main>
    <Toolbar></Toolbar>
    <TabMenu></TabMenu>
    <transition name="slide-x-transition" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-main>
</template>
<script>
import Toolbar from "../components/Toolbar.vue";
import TabMenu from "../components/TabsMenu.vue";
export default {
  name: "MasterPage",
  components: { Toolbar, TabMenu }
};
</script>
