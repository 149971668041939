<template>
  <v-tabs
    background-color="accent"
    slider-color="primary"
    show-arrows
    align-with-title
  >
    <v-tab v-for="(item, index) in tabsMenu" :key="index" :to="item.to">{{
      item.text
    }}</v-tab>
  </v-tabs>
</template>
<script>
import { restrictions } from "../common/RestrictionsCode";

export default {
  name: "TabsNavbar",
  data() {
    return {
      items: [
        {
          to: { name: "users" },
          text: this.$t("tabMenu.admin"),
          auth: this.$can("admin")
        },
        {
          to: { name: "rodo" },
          text: this.$t("tabMenu.user"),
          auth: this.$can("hasRestriction", { code: restrictions.dataBrowsing })
        },
        {
          to: { name: "contactAgreementReport" },
          text: this.$t("tabMenu.reports"),
          auth: this.$can("hasRestriction", {
            code: restrictions.reportsCreate
          })
        }
      ]
    };
  },
  computed: {
    tabsMenu() {
      return this.items.filter(w => w.auth);
    }
  }
};
</script>
<style scoped>
.v-tab {
  width: 256px;
  letter-spacing: 0;
}
.v-tab--active.v-tab {
  color: #000000 !important;
}
</style>
