<template>
  <v-badge v-model="badgeVisible" left overlap color="warning">
    <span slot="badge">{{ counterValue }}</span>
    <v-btn text icon :color="iconColor" :to="{ name: 'tasks' }" exact>
      <fa-icon :icon="activeIcon" size="lg"></fa-icon>
    </v-btn>
  </v-badge>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TasksBell",
  props: {
    activeIcon: {
      type: String,
      default: "bell"
    }
  },
  mounted() {
    this.getTasks();
  },
  computed: {
    ...mapGetters({ counterValue: "taskCount" }),
    badgeVisible() {
      return this.counterValue != 0;
    },
    iconColor() {
      return this.badgeVisible ? "primary" : "grey";
    }
  },
  methods: {
    ...mapActions({ getTasks: "SET_TASKS" })
  }
};
</script>
<style lang="scss" scoped>
span {
  ::v-deep &.v-badge--overlap.v-badge--left .v-badge__badge {
    font-size: 12px;
    top: -2px;
    left: -3px;
  }
}
</style>
