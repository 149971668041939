<template>
  <v-toolbar text color="white">
    <v-toolbar-title v-ripple="{ class: 'white--text' }">
      <router-link :to="{ name: 'rodo' }">
        <v-layout>
          <Logo></Logo>
        </v-layout>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <TaskBell></TaskBell>
    <UserSettings> </UserSettings>
  </v-toolbar>
</template>
<script>
import Logo from "./Logo";
import TaskBell from "./TasksBell";
import UserSettings from "./UserSettings";
export default {
  name: "Toolbar",
  components: { Logo, UserSettings, TaskBell }
};
</script>
